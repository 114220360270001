<script setup lang="ts">
import type { SliceInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

const props = defineProps<{
  slice: SliceInterface
  fields: {
    image1: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    titleSize: VoixSelectFieldInterface
    titleTag: VoixSelectFieldInterface
    illustration: VoixSelectFieldInterface
    copy: VoixWysiwygFieldInterface
    imagePosition: VoixSelectFieldInterface
    copyPosition: VoixSelectFieldInterface
  }
}>()

defineSlice({
  name: {
    label: 'Sanctuary Fade Color Block',
    group: 'Sanctuary',
    layouts: ['Sanctuary2024'],
  },
  preview: 'SlicesSanctuaryFadeColorBlock.jpg',
  description: '',
  fields: {
    image1: { type: 'media', label: 'Image', breakpoints: {
      md: { width: 800, height: 600 },
      sm: { width: 400, height: 300 },
    } },

    title: {
      type: 'text',
      label: 'Title',
      group: 'Title',
    },

    titleSize: {
      type: 'select',
      label: 'Title Size',
      group: 'Title',
      options: {
        'text-sm': 'Small',
        'text-base': 'Base',
        'text-lg': 'Large',
        'text-xl': 'XL',
        'text-2xl': '2XL',
        'text-3xl': '3XL',
        'text-4xl': '4XL',
        'text-5xl': '5XL',
      },
      default: 'text-2xl',
    },
    illustration: {
      label: 'Illustration',
      type: 'select',
      enabled: true,
      options: { none: 'None', seahorse: 'Seahorse', sisserou: 'Sisserou', turtle: 'Turtle' },
      default: 'seahorse',
    },
    titleTag: {
      type: 'select',
      label: 'Title Tag',
      group: 'Title',
      options: { h1: 'h1', h2: 'h2', h3: 'h3', h4: 'h4', h5: 'h5', h6: 'h6' },
      default: 'h3',
    },
    copy: {
      type: 'wysiwyg',
      label: 'Copy',
    },
    imagePosition: {
      type: 'select',
      label: 'Image Position',
      options: {
        top: 'Top',
        bottom: 'Bottom',
        left: 'Left',
        right: 'Right',
      },
      default: 'left',
    },
    copyPosition: {
      type: 'select',
      label: 'Copy Position',
      options: {
        top: 'Top',
        bottom: 'Bottom',
        center: 'Center',
        left: 'Left',
        middle: 'Middle',
        right: 'Right',
      },
      default: 'right',
    },
  },
})

const copyColor = computed(() => {
  if (
    props.slice.properties?.settings &&
    props.slice.properties?.settings.length > 0 &&
    props.slice.properties?.settings[0].color
  ) {
    return {color: props.slice.properties?.settings[0].color}
  }
  return {color:'white'}
})
</script>

<template>
  <div class="w-full bg-sanctuary-primary z-100">
    <div v-if="fields.imagePosition" class="container mx-auto">
      <component
        :is="fields.titleTag.value || 'h1'"
        :class="fields.titleSize.value"
        class="hidden md:block w-1/2 mb-10 font-serif font-light"
      >
        <div :style="copyColor" class="font-light" v-html="fields.title.text" />
      </component>
      <div
        class="md:grid md:gap-8"
        :class="{
          'md:grid-cols-1': fields.imagePosition.value === 'top' || fields.imagePosition.value === 'bottom',
          'md:grid-cols-5': fields.imagePosition.value === 'right' || fields.imagePosition.value === 'left',
        }"
      >
        <VoixMedia
          :field="fields.image1" :class="{
            'md:row-start-2': fields.imagePosition.value === 'bottom',
            'md:col-span-3': fields.imagePosition.value === 'left',
            'md:col-start-2 md:row-start-1 md:col-span-3': fields.imagePosition.value === 'right',
          }"
        />
        <div
          class="mt-8 md:mt-0 backdrop:flex col-span-2"
          :class="{
            'md:row-start-1  text-right': fields.imagePosition.value === 'right',
            'items-start': fields.copyPosition.value === 'top',
            'items-center': fields.copyPosition.value === 'center',
            'items-end': fields.copyPosition.value === 'bottom',
            'justify-center text-center': fields.copyPosition.value === 'middle',
            'justify-end text-right': fields.copyPosition.value === 'right',
          }"
        >
          <div>
            <div v-if="fields.illustration.enabled && fields.illustration.value" class="flex mb-6">
              <SanctuaryIllustration v-if="typeof fields.illustration.value !== 'undefined'" :drawing="fields.illustration.value" />
            </div>
            <component
              :is="fields.titleTag.value || 'h1'"
              :class="fields.titleSize.value"
              class="md:hidden w-1/2 mb-10 font-serif font-light"
            >
              <div class="font-light text-2xl" 
                :style="copyColor"
                v-html="fields.title.text" />
            </component>
            <VoixWysiwyg :field="fields.copy" class="prose-a:underline leading-[2em] font-normal" :style="copyColor" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
